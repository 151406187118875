import React from "react";
import Body from "components/Body";
import Branding from "components/Branding";
import ImageSwiper from "components/Swiper/ImageSwiper";
import classNames from "lib/classNames";

export default function IllustratedBlock({ data, variant = "institution" }) {
  const themes = {
    default: {
      text: "text-default",
      prose: "prose-default",
    },
    primary: {
      text: "text-primary",
      prose: "prose-primary",
    },
    institution: {
      text: "text-institution",
      prose: "prose-institution",
    },
    formation: {
      text: "text-formation",
      prose: "prose-formation",
    },
    internship: {
      text: "text-internship",
      prose: "prose-internship",
    },
    news: {
      text: "text-news",
      prose: "prose-news",
    },
    inscription: {
      text: "text-inscription",
      prose: "prose-inscription",
    },
  };

  return (
    <div className="container">
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 2xl:gap-32">
        <div className="relative">
          <h2
            className={classNames(
              "absolute top-0 left-0 py-5 pr-10 bg-white font-important font-bold text-4xl 2xl:text-5xl uppercase -translate-y-1/3 z-10",
              themes[variant].text
            )}
          >
            {data.info}
          </h2>
          <ImageSwiper images={data.field_images} />
        </div>
        <div>
          <div className="hidden 2xl:block -ml-8">
            <Branding />
          </div>
          <div className={classNames("prose", themes[variant].prose)}>
            <Body value={data.body.value} variant={variant} />
          </div>
        </div>
      </div>
    </div>
  );
}
