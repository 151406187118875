
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import { getResource, getResourceCollection } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import useTranslation from "next-translate/useTranslation";
import IllustratedBlock from "components/Block/IllustratedBlock";
import TermProfileList from "components/Profile/TermProfileList";
import NodeSiteList from "components/Site/NodeSiteList";
import NumbersBlock from "components/Block/NumbersBlock";
import NodeUsefullLinkList from "components/UsefullLink/NodeUsefullLinkList";
import NodeBannerList from "components/Banner/NodeBannerList";
import IconTopRightPattern from "components/Icons/IconTopRightPattern";

export default function IndexPage({ blocks, terms, nodes, globals }) {
  const { t } = useTranslation("home");

  return (
    <Layout globals={globals} home>
      <Meta title={t("meta.title")} />

      {nodes?.banners?.length > 0 && (
        <section className="relative">
          <NodeBannerList nodes={nodes.banners} />
        </section>
      )}

      {blocks?.intro && (
        <section className="relative py-16 xl:py-36">
          <IconTopRightPattern className="absolute top-0 right-0 h-[11.45vw] w-[11.45vw] opacity-80 text-institution" />
          <IllustratedBlock data={blocks.intro} />
        </section>
      )}

      {terms?.profiles && (
        <section className="relative py-16 xl:py-36 bg-formation xl:bg-transparent">
          <TermProfileList terms={terms.profiles} />
        </section>
      )}

      {nodes?.sites?.length > 0 && (
        <section className="relative py-16 xl:py-36 bg-institution xl:bg-transparent">
          <NodeSiteList nodes={nodes.sites} />
        </section>
      )}

      {blocks?.numbers && (
        <section className="relative py-16 xl:py-36">
          <NumbersBlock data={blocks.numbers} />
        </section>
      )}

      {nodes?.links?.length > 0 && (
        <section className="relative py-16 xl:py-36">
          <NodeUsefullLinkList nodes={nodes.links} />
        </section>
      )}
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const bannersParams = new DrupalJsonApiParams();
  bannersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addPageLimit(5);
  const banners = await getResourceCollection("node--banner", {
    params: bannersParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const introParams = new DrupalJsonApiParams();
  introParams.addInclude(["field_images"]);

  const profilesParams = new DrupalJsonApiParams();
  profilesParams.addInclude(["field_image"]).addSort("weight", "ASC");
  const profiles = await getResourceCollection("taxonomy_term--profile_types", {
    params: profilesParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const sitesParams = new DrupalJsonApiParams();
  sitesParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addPageLimit(2);
  const sites = await getResourceCollection("node--site", {
    params: sitesParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const linksParams = new DrupalJsonApiParams();
  linksParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addPageLimit(3);
  const links = await getResourceCollection("node--useful_link", {
    params: linksParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  return {
    props: {
      globals: await getGlobals(context),
      blocks: {
        intro: await getResource(
          "block_content--illustrated",
          "e9d29771-3c36-4f39-ac3e-73e11ea00d6e",
          {
            params: introParams.getQueryObject(),
          }
        ),
        numbers: await getResource(
          "block_content--numbers",
          "e7f7a85f-a822-42e4-a067-0b6cb8497f5c"
        ),
      },
      terms: {
        profiles,
      },
      nodes: {
        banners,
        sites,
        links,
      },
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  