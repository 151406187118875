import Body from "components/Body";
import Button from "components/Button";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import React from "react";
export default function NodeUsefullLinkTeaser({ node }) {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col gap-6 text-center h-full">
      <h3 className="sr-only">{node.title}</h3>
      <div className="flex-initial">
        <div className="aspect-w-16 aspect-h-10">
          <Image
            src={node.field_image.image_style_uri.large_scale}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </div>
      {node.body && (
        <div className="flex-1 prose max-w-none text-lg mt-6 mb-12">
          <Body value={node.body.processed} />
        </div>
      )}
      {node.field_link && (
        <div className="flex-initial">
          <Link href={node.field_link.url} passHref>
            <Button as="a" theme="institution" size="small">
              {t("utils.more_link")}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
