import React from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import IconDotsPattern from "components/Icons/IconDotsPattern";
import TermProfileTeaser from "components/Profile/TermProfileTeaser";
import Button from "components/Button";

export default function TermProfileList({ terms }) {
  const { t } = useTranslation("home");
  return (
    <div className="relative">
      <div
        className="hidden xl:block absolute top-1/2 inset-x-0 bottom-0 bg-formation"
        aria-hidden="true"
      />
      <div
        className="hidden xl:block absolute top-1/2 inset-x-0 h-40 bg-squares-pattern bg-contain bg-repeat-x opacity-20"
        aria-hidden="true"
      />
      <IconDotsPattern className="hidden xl:block absolute top-12 left-1/2 w-[40vw] text-default-light" />
      <div
        className="hidden xl:block absolute top-0 inset-x-0 bottom-1/2 max-w-3xl mx-auto bg-formation"
        aria-hidden="true"
      />
      <IconDotsPattern className="hidden xl:block absolute -top-32 right-1/2 w-[40vw] text-default-light" />
      <div className="relative container">
        <div className="relative xl:max-w-3xl mx-auto xl:px-16 xl:py-11 mb-16 xl:mb-0">
          <h2 className="relative font-important font-bold font-default text-4xl 2xl:text-5xl uppercase">
            {t("formations.title")}
          </h2>
        </div>
        <div className="relative xl:py-16">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 2xl:gap-24">
            {terms.map((term) => {
              return (
                <div key={term.id}>
                  <TermProfileTeaser term={term} />
                </div>
              );
            })}
          </div>
          <div className="mt-16 text-center">
            <Link href={t("common:formation.list.path")} passHref>
              <Button as="a" theme="white" size="small">
                <span>{t("formations.more")}</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
