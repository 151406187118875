import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Pagination } from "swiper";
import NodeBannerTeaser from "components/Banner/NodeBannerTeaser";
import IconBannerPattern from "components/Icons/IconBannerPattern";
import classNames from "lib/classNames";

export default function NodeBannerList({ nodes, variant = "institution" }) {
  const themes = {
    default: {
      background: "bg-default",
    },
    primary: {
      background: "bg-primary",
    },
    institution: {
      background: "bg-institution",
    },
    formation: {
      background: "bg-formation",
    },
    internship: {
      background: "bg-internship",
    },
    news: {
      background: "bg-news",
    },
    inscription: {
      background: "bg-inscription",
    },
  };

  return (
    <section className="relative">
      <Swiper
        slidesPerView={1}
        modules={[A11y, Autoplay, Pagination]}
        autoplay={{
          delay: 5000,
        }}
        pagination={{
          clickable: true,
          bulletClass:
            "image-swiper-pagination relative inline-block mx-2 h-4 w-4 rounded-full transition-colors duration-300",
          bulletActiveClass: classNames(
            "image-swiper-pagination-active",
            themes[variant].background
          ),
        }}
      >
        {nodes.map((node) => {
          return (
            <SwiperSlide key={node.id}>
              <NodeBannerTeaser node={node} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <IconBannerPattern className="absolute bottom-0 right-0 -mb-px w-[34.3vw] h-[11.45vw] text-white opacity-40 z-10" />
    </section>
  );
}
