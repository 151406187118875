import React from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import ThemeIcon from "components/ThemeIcon";
import classNames from "lib/classNames";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";

export default function NodeBannerTeaser({ node }) {
  const { t } = useTranslation("common");
  const variant = node.field_variant || "institution";
  const themes = {
    default: {
      background: "bg-default",
      xlBackground: "xl:bg-default",
      text: "text-white",
    },
    primary: {
      background: "bg-primary",
      xlBackground: "xl:bg-primary",
      text: "text-white",
    },
    institution: {
      background: "bg-institution",
      xlBackground: "xl:bg-institution",
      text: "text-white",
    },
    formation: {
      background: "bg-formation",
      xlBackground: "xl:bg-formation",
      text: "text-default-dark",
    },
    pastorale: {
      background: "bg-primary",
      xlBackground: "xl:bg-primary",
      text: "text-white",
    },
    internship: {
      background: "bg-internship",
      xlBackground: "xl:bg-internship",
      text: "text-white",
    },
    news: {
      background: "bg-news",
      xlBackground: "xl:bg-news",
      text: "text-white",
    },
    inscription: {
      background: "bg-inscription",
      xlBackground: "xl:bg-inscription",
      text: "text-white",
    },
  };

  return (
    <div
      className={classNames(
        "aspect-w-16 aspect-h-14 md:aspect-h-10 lg:aspect-h-7",
        themes[variant].background
      )}
    >
      <figure>
        <Image
          src={node.field_image.image_style_uri.hq}
          {...node.field_image.resourceIdObjMeta}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          className="opacity-60 xl:opacity-100"
        />
      </figure>
      <div className="container flex flex-col justify-end py-16 2xl:py-32 h-full">
        <div className="relative text-center xl:text-left">
          <div className="flex flex-col xl:pl-16 xl:pt-16">
            <h2 className="mb-4 xl:mb-0">
              <span
                className={classNames(
                  "inline-block xl:px-10 xl:py-4 bg-opacity-80 font-important font-bold text-4xl lg:text-5xl xl:text-6xl uppercase",
                  themes[variant].xlBackground,
                  themes[variant].text
                )}
              >
                {node.title}
              </span>
            </h2>
            {node.field_baseline && (
              <div className="hidden lg:block px-10 py-4 ">
                <p className="text-3xl text-white text-shadow">
                  {node.field_baseline}
                </p>
              </div>
            )}
            {node.field_link && (
              <div>
                <Link href={node.field_link.url} passHref>
                  <a
                    className={classNames(
                      "inline-flex items-center gap-2 px-4 py-2 xl:bg-opacity-80 font-important font-bold uppercase",
                      themes[variant].background,
                      themes[variant].text
                    )}
                  >
                    <span>{t("utils.more_link")}</span>
                    <ArrowRightIcon className="h-4 w-4" />
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
