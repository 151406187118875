import IconClassroom from "components/Icons/IconClassroom";
import IconStudent from "components/Icons/IconStudent";
import IconTeacher from "components/Icons/IconTeacher";
import useTranslation from "next-translate/useTranslation";
import React from "react";

export default function NumbersBlock({ data }) {
  const { t } = useTranslation("home");
  return (
    <div className="container">
      <h2 className="sr-only">{data.name}</h2>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 xl:gap-24">
        <div className="flex flex-col gap-4 xl:gap-6 items-center text-center">
          <IconStudent className="w-[100px] h-[100px] text-institution" />
          <span className="font-important font-bold leading-none xl:leading-tight text-[110px] text-institution">
            {data.field_students}
          </span>
          <span
            className="h-1 w-10 mx-auto bg-institution"
            aria-hidden="true"
          />
          <span className="font-important font-bold uppercase text-2xl">
            {t("numbers.students")}
          </span>
        </div>
        <div className="flex flex-col gap-6 items-center text-center">
          <IconClassroom className="w-[100px] h-[100px] text-institution" />
          <span className="font-important font-bold leading-tight text-[110px] text-institution">
            {data.field_classrooms}
          </span>
          <span
            className="h-1 w-10 mx-auto bg-institution"
            aria-hidden="true"
          />
          <span className="font-important font-bold uppercase text-2xl">
            {t("numbers.classrooms")}
          </span>
        </div>
        <div className="flex flex-col gap-6 items-center text-center">
          <IconTeacher className="w-[100px] h-[100px] text-institution" />
          <span className="font-important font-bold leading-tight text-[110px] text-institution">
            {data.field_teachers}
          </span>
          <span
            className="h-1 w-10 mx-auto bg-institution"
            aria-hidden="true"
          />
          <span className="font-important font-bold uppercase text-2xl">
            {t("numbers.teachers")}
          </span>
        </div>
      </div>
    </div>
  );
}
