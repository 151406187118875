import React from "react";
import NodeUsefullLinkTeaser from "components/UsefullLink/NodeUsefullLinkTeaser";
import useTranslation from "next-translate/useTranslation";

export default function NodeUsefullLinkList({ nodes }) {
  const { t } = useTranslation("home");

  return (
    <div className="container">
      <h2 className="relative mb-16 font-important font-bold font-default text-4xl xl:text-5xl uppercase text-center mx-auto text-institution">
        {t("links.title")}
      </h2>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 xl:gap-20">
        {nodes.map((node) => {
          return (
            <div key={node.id}>
              <NodeUsefullLinkTeaser node={node} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
